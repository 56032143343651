.contact {
    min-height: 700px;
    &__item {
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        a {
            color: #848484; } } }

.headers {
    text-align: center; }
