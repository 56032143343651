.person {
    padding-top: 40px;
    &__filmer {
        font-size: 14px;
        font-family: $nanum;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center; }
    &__film {
        display: none; }
    &__teatr {
        display: none; }
    &__name {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px; }
    &__list {
        .list__item {
            list-style-type: none;
            position: relative;
            padding-left: 30px;
            &::before {
                content: '';
                width: 15px;
                height: 15px;
                background: url(../img/film.png);
                position: absolute;
                top: 4px;
                left: 0;
                background-size: contain; } }
        .list__hidden {
            display: none; } }
    &__more {
        text-align: center;
        a {
            padding: 5px 10px;
            color: #ffffff;
            background: #848484; } } }

.glide {
    margin-top: 100px;
    max-height: 600px;
    img {
        max-width: 100%;
        max-height: 100%; }
    &__arrow {
        background: #848484;
        font-size: 12px;
        padding: 5px; }
    &__track {
        height: 600px; }
    &__slide {
        text-align: center; }
    &__wrapper {
        max-width: 700px;
        margin: 0 auto; } }

.active {
    display: block !important;
    transition: 0.3 all ease; }

.filmInfo {
    cursor: pointer;
    margin-left: 10px;
    background: url(../img/sasha/plus.svg);
    width: 14px;
    display: inline-block;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat; }

.filmInfo-minus {
    cursor: pointer;
    margin-left: 10px;
    background: url(../img/sasha/minus.svg);
    width: 14px;
    display: inline-block;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat; }
.teatrInfo {
    cursor: pointer;
    margin-left: 10px;
    background: url(../img/sasha/plus.svg);
    width: 14px;
    display: inline-block;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat; }

.teatrInfo-minus {
    cursor: pointer;
    margin-left: 10px;
    background: url(../img/sasha/minus.svg);
    width: 14px;
    display: inline-block;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat; }



.photoOut {
    margin: 0 auto;
    display: flex;
    max-width: 780px;
    flex-wrap: wrap;
    &-item {
        width: 46%;
        margin: 1%;
        img {
            max-width: 100%; } } }

.photoAct {
    display: flex;
    max-width: 780px;
    flex-wrap: wrap;
    margin: 0 auto;
    .person__img {
        width: 46%;
        margin: 1%;
        img {
            max-width: 100% !important; } }
    .person__info {
        width: 46%;
        margin: 1%; } }

.photoWrapper {
    margin: 0 auto; }
