.actor {
    min-height: 800px;
    a {
        color: #000;
        &:hover {
            text-decoration: none; } }

    &__img {
        width: 255px;
        height: 255px;
        object-fit: cover;
        object-position: center center;
        img {
            max-width: 100%;
            width: 255px;
            height: 255px;
            object-fit: cover;
            object-position: top center; } }
    &__name {
        padding-top: 10px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        font-family: 'EuropaNuovaBold', sans-serif; }
    &__item {
        margin-bottom: 20px;
        &:hover {
            .actor__name {
                width: 100%;
                color: #b1566f; } } } }


.headers {
    font-size: 25px;
    text-align: center;
    font-family: $fira;
    margin-bottom: 45px;
    margin-top: 20px;
    fz {} }
.crumbs {
    max-width: 1920px; }

.breadcrumb {
    padding: 4px 0 4px 30px;
    &-item {
        &.active {
            color: #000; }
        a {
            color: #848484; } } }

