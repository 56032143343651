/**********/
/* Fonts */
@import url(../css/fonts/ProximaNova.css);
/***********/
/* Blocks */
.hidden {
	display: none;
}

.header {
	height: 80px;
}

.header .container {
	height: 100%;
}

.header .container .row {
	height: 100%;
}

.header__name {
	width: 100%;
	text-align: center;
	line-height: 40px;
	font-family: "Roboto Mono", monospace;
	font-size: 32px;
	font-weight: bold;
	letter-spacing: 39px;
	position: relative;
}

.header__center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.header__center a {
	color: #000;
}

.header__link--all {
	color: #fff;
	padding: 5px 10px;
}

.header__link--all:hover {
	text-decoration: none;
}

.header__right {
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight: bold;
	font-family: "PT Serif", serif;
	font-size: 16px;
}

.header__right a {
	color: #000;
}

.header__contact {
	margin-right: 16px;
	font-family: "Segoe UI", sans-serif;
}

.header__sign {
	text-transform: uppercase;
	color: #000;
	font-size: 16px;
	letter-spacing: 11px;
}

.header__lang {
	display: none;
}

@media screen and (max-width: 992px) {
	.header {
		height: auto;
		border-bottom: 2px solid black;
	}
	.header__contact {
		width: 100%;
	}
	.header__contact a {
		float: right;
		font-size: 16px;
	}
}

@media screen and (max-width: 500px) {
	.header__name {
		letter-spacing: 10px;
	}
	.header__sign {
		letter-spacing: 1px;
	}
	.header {
		height: auto;
		border-bottom: 2px solid black;
	}
	.photoAct {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 auto 20px !important;
	}
	.photoAct .person__img {
		width: 95% !important;
	}
	.photoAct .person__info {
		width: 95% !important;
	}
	.photoOut {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.photoOut-item {
		width: 95% !important;
	}
}

.actor {
	min-height: 800px;
}

.actor a {
	color: #000;
}

.actor a:hover {
	text-decoration: none;
}

.actor__img {
	width: 255px;
	height: 255px;
	object-fit: cover;
	object-position: center center;
}

.actor__img img {
	max-width: 100%;
	width: 255px;
	height: 255px;
	object-fit: cover;
	object-position: top center;
}

.actor__name {
	padding-top: 10px;
	text-align: center;
	font-weight: 400;
	font-size: 18px;
	font-family: 'EuropaNuovaBold', sans-serif;
}

.actor__item {
	margin-bottom: 20px;
}

.actor__item:hover .actor__name {
	width: 100%;
	color: #b1566f;
}

.headers {
	font-size: 25px;
	text-align: center;
	font-family: "Fira Sans", sans-serif;
	margin-bottom: 45px;
	margin-top: 20px;
}

.crumbs {
	max-width: 1920px;
}

.breadcrumb {
	padding: 4px 0 4px 30px;
}

.breadcrumb-item.active {
	color: #000;
}

.breadcrumb-item a {
	color: #848484;
}

.footer {
	margin-top: 30px;
	background: #f1f1f1;
}

.footer__wrapper {
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}

.footer__wrapper div {
	margin-right: 30px;
}

.footer__wrapper a {
	color: #848484;
}

.person {
	padding-top: 40px;
}

.person__filmer {
	font-size: 14px;
	font-family: "Nanum Gothic", sans-serif;
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.person__film {
	display: none;
}

.person__teatr {
	display: none;
}

.person__name {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 15px;
}

.person__list .list__item {
	list-style-type: none;
	position: relative;
	padding-left: 30px;
}

.person__list .list__item::before {
	content: '';
	width: 15px;
	height: 15px;
	background: url(../img/film.png);
	position: absolute;
	top: 4px;
	left: 0;
	background-size: contain;
}

.person__list .list__hidden {
	display: none;
}

.person__more {
	text-align: center;
}

.person__more a {
	padding: 5px 10px;
	color: #ffffff;
	background: #848484;
}

.glide {
	margin-top: 100px;
	max-height: 600px;
}

.glide img {
	max-width: 100%;
	max-height: 100%;
}

.glide__arrow {
	background: #848484;
	font-size: 12px;
	padding: 5px;
}

.glide__track {
	height: 600px;
}

.glide__slide {
	text-align: center;
}

.glide__wrapper {
	max-width: 700px;
	margin: 0 auto;
}

.active {
	display: block !important;
	transition: 0.3 all ease;
}

.filmInfo {
	cursor: pointer;
	margin-left: 10px;
	background: url(../img/sasha/plus.svg);
	width: 14px;
	display: inline-block;
	height: 14px;
	background-size: contain;
	background-repeat: no-repeat;
}

.filmInfo-minus {
	cursor: pointer;
	margin-left: 10px;
	background: url(../img/sasha/minus.svg);
	width: 14px;
	display: inline-block;
	height: 14px;
	background-size: contain;
	background-repeat: no-repeat;
}

.teatrInfo {
	cursor: pointer;
	margin-left: 10px;
	background: url(../img/sasha/plus.svg);
	width: 14px;
	display: inline-block;
	height: 14px;
	background-size: contain;
	background-repeat: no-repeat;
}

.teatrInfo-minus {
	cursor: pointer;
	margin-left: 10px;
	background: url(../img/sasha/minus.svg);
	width: 14px;
	display: inline-block;
	height: 14px;
	background-size: contain;
	background-repeat: no-repeat;
}

.photoOut {
	margin: 0 auto;
	display: flex;
	max-width: 780px;
	flex-wrap: wrap;
}

.photoOut-item {
	width: 46%;
	margin: 1%;
}

.photoOut-item img {
	max-width: 100%;
}

.photoAct {
	display: flex;
	max-width: 780px;
	flex-wrap: wrap;
	margin: 0 auto;
}

.photoAct .person__img {
	width: 46%;
	margin: 1%;
}

.photoAct .person__img img {
	max-width: 100% !important;
}

.photoAct .person__info {
	width: 46%;
	margin: 1%;
}

.photoWrapper {
	margin: 0 auto;
}

.contact {
	min-height: 700px;
}

.contact__item {
	text-align: center;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}

.contact__item a {
	color: #848484;
}

.headers {
	text-align: center;
}
