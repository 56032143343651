/**********/
/* Fonts */
@import url(../css/fonts/ProximaNova.css);

/***********/
/* Blocks */
@import '../../blocks/common/variables';
@import '../../blocks/header/style';
@import '../../blocks/block1/style';
@import '../../blocks/footer/style';
@import '../../blocks/sasha/style';
@import '../../blocks/contact/style';



