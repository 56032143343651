.footer {
    margin-top: 30px;
    background: #f1f1f1;

    &__wrapper {
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        div {
            margin-right: 30px; }
        a {
            color: #848484; } } }
